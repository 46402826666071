<template>
  <div class="welcome" :style="backgroundStyle">
    <div class="gradient-box"></div>
    <div class="text-section">
      <h1 class="welcome-title">{{ title }}</h1>
      <p class="subtitle">{{ subtitle }}</p>
      <!-- <VolunteerButton /> -->
      <div class="button">
        <button v-show="show" @click="navigateToActivities()">GET INVOLVED</button>
      </div>
    </div>
  </div>
</template>

<script>
// import VolunteerButton from "@/components/VolunteerButton.vue";
export default {
  name: "Welcome",
  components: {
    // VolunteerButton,
  },
  methods:{
    navigateToActivities(){
      this.$router.push('/activities')
    }
  },
  props: {
    title: String,
    subtitle: String,
    backgroundStyle: Array[""],
    show: Boolean
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.welcome {
  position: relative;
  /* background-image: url("../assets/img/welcome_image.png"); */
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 700px;
}

.text-section {
  position: absolute;
  left: 5%;
  bottom: 2%;
}

h1.welcome-title {
  max-width: 600px;
  color: #006233;
  /* font-family: "Gill Sans"; */
  font-size: 63px;
  font-weight: 700;
  line-height: 73px;
  text-align: start;
}
.subtitle {
  max-width: 800px;
  white-space: pre-line;
  width: 90%;
  color: #006233;
  font-size: 28px;
  font-weight: 600;
  line-height: 33px;
  text-align: start;
}
.gradient-box {
  height: 100%;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, #fff 90%);
  position: absolute;
  bottom: 0%;
  z-index: 0;
}
.button{
  display: grid;
  place-items: left;
}
button {
  max-width: 200px;
  background-color: #006233;
  color: #FFF;
  padding: 10PX;
  font-weight: bold;
  font-size: 20px;
  border: none;
}
@media screen and (max-width:720px){
  h1.welcome-title {
  max-width: 600px;
  font-size: 43px;
  font-weight: 700;
  line-height: 43px;
  text-align: start;
}
}
</style>
