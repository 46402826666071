<template>
  <div id="contact">
    <div class="contact">
      <div class="CallToAction">
        <div>
          <div class="socialmedia">
            <a href="">
              <img src="../assets/icons/facebook.png" />
            </a>
            <a href="">
              <img src="../assets/icons/instagram.png" />
            </a>
          </div>
          <div class="location">
            <p>The Green Living Planet</p>
            <p>Sombetini, Arusha</p>
            <p>Tanzania, East Africa</p>
          </div>
          <br />
          <div class="callToAction">
            <a href="mailto:info@greenlivingplanet.org"
              >info@greenlivingplanet.org</a
            ><br />
            <a class="call" href="tel:+255764181825">+255 764 181 825</a>
            <p>P.O Box 14888 Arusha</p>
          </div>
        </div>
      </div>
      <div class="form">
        <form class="contactForm" action="">
          <table>
            <tr>
              <td>
                <label for="">Name</label> <br />
                <input
                  type="text"
                  placeholder="John Smith"
                  id="UserName"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <label for="">Email</label> <br />
                <input
                  type="emain"
                  placeholder="example@email.com"
                  id="UserPhone"
                />
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <label for="">Phone</label> <br />
                <input
                  type="text"
                  placeholder="+255 (0) 000 000 000"
                  id="UserEmail"
                  required
                />
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <label for="">Name</label> <br />
                <textarea
                  name=""
                  placeholder="I would like to volunteer  for..."
                  id="TextArea"
                  required
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>
                <br />
                <button @click="submit">SUBMIT</button>
              </td>
            </tr>
          </table>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newLine: "%0D%0A",
      headerName: "Full Name",
      headerPhone: "Phone Number",
      headerEmail: "Email Address",
      headerMessage: "Message",
    };
  },
  methods: {
    submit() {
      const name = document.getElementById("UserName").value;
      const phone = document.getElementById("UserPhone").value;
      const email = document.getElementById("UserEmail").value;
      const message = document.getElementById("TextArea").value;

      if (name == "" || phone == "") {
        alert("Please Fill All Required Fields");
      } else {
        const body =
          this.headerName +
          ":" +
          " " +
          name +
          this.newLine +
          this.headerPhone +
          ":" +
          " " +
          phone +
          this.newLine +
          this.headerEmail +
          ":" +
          " " +
          email +
          this.newLine +
          this.headerSubject +
          ":" +
          " " +
          message +
          this.newLine;

        console.log(body);
        window.open(
          "mailto:titus@nyayozang.com?subject=The Green Living Planet Client&body=" +
            body
        );
      }
    },
  },
};
</script>

<style scoped>
.contact {
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: linear-gradient(
    180deg,
    rgba(0, 98, 51, 0) 0%,
    rgba(0, 98, 51, 1) 20%
  );
  /* background-color: #006233; */
  width: 100%;
  /* height:800px; */
  display: grid;
  place-items: center;
}
.CallToAction,
.form {
  width: 100%;
}
button {
  height: 54px;
  width: 230px;
  color: #006233;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  text-align: center;
}
.socialmedia a {
  margin: 20px;
  /* background-color: lawngreen; */
}
a {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
}
p {
  color: #fff;
  /* font-family: "Gill Sans"; */
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  text-align: center;
}
img {
  height: 32px;
}
.contact {
  display: flex;
}
a.call {
  text-decoration: none;
}
.CallToAction {
  display: grid;
  place-items: center;
}
table {
  margin: auto;
  max-width: 400px;
  width: 100%;
  color: #fff;
  text-align: left;
}
textarea {
  resize: none;
  height: 100px;
}
input,
textarea {
  width: 100%;
  background-color: #c8c8c8;
  border-radius: 4px;
  padding: 5px;
  border: none;
  color: #006233;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
input {
  height: 30px;
}
@media screen and (max-width: 720px) {
  .contact {
    display: block;
  }
  button {
    width: 100%;
  }
  table {
    width: 90%;
  }
  a {
    font-size: 18px;
    line-height: 30px;
    font-weight: 400;
  }
  p {
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
  }
  .form{
    margin: auto;
    width: 90%;
  }
}
</style>