<template>
  <div :class="{ programDetails: true, programDetailsReverse: id % 2 === 0 }">
    <div class="image"><img :src="Programimage" /></div>
    <div class="Description">
      <div>
        <h2>{{ Title }}</h2>
        <p>{{ Description }} <br /></p>
        <p @click="scrollTo('accomodation')" v-show="showToAccomodation">
          <router-link to="/about">Get Involved</router-link>
        </p>
        <p @click="scrollTo('nav')" v-show="showToGetInvolved">
          <router-link to="/activities">Get Involved</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: Number,
    Title: String,
    Description: String,
    Programimage: String,
    showToAccomodation: Boolean,
    showToGetInvolved: Boolean,
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
};
</script>

<style scoped>
.programDetails {
  display: flex;
  text-align: left;
}
.programDetailsReverse {
  flex-direction: row-reverse;
}
.Description p,
.Description h2 {
  margin: 20px;
}
.Description h2 {
  color: #006233;
  /* font-family: "Gill Sans"; */
  font-size: 30px;
  font-weight: 600;
  line-height: 33px;
}
.image {
  display: grid;
  place-items: center;
}
.image img {
  width: 80%;
  margin: auto;
}
.image,
.Description {
  width: 100%;
}
.Description p {
  max-width: 530px;
  color: #131313;
  /* font-family: "Gill Sans"; */
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
}
a::after {
  content: "";
  height: 3px;
  background-color: #006233;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 25px;
}
a {
  position: relative;
  margin-top: 20px;
  color: #006233;
  text-decoration: #006233;
  font-weight: bold;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
.Description {
  display: grid;
  place-items: center;
}
@media screen and (max-width: 720px) {
  .programDetails {
    display: block;
  }
  .Description p {
    width: 90%;
    color: #131313;
    text-align: center;
    /* font-family: "Gill Sans"; */
    font-size: 16px;
  }
  .Description h2 {
    font-size: 20px;
    text-align: center;
  }
  image {
    display: grid;
    place-items: center;
  }
  .image img {
    width: 100%;
    margin: auto;
  }
}
</style>