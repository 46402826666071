<template>
  <div class="gallery-section">
    <lingallery
      :iid.sync="currentId"
      :width="width"
      :height="height"
      :responsive="responsive"
      :items="items"
    />
  </div>
</template>

<script>
import Lingallery from "lingallery";
export default {
  components: {
    Lingallery,
  },

  data() {
    return {
      height: 600,
      width: 300,
      responsive: true,
      currentId: null,
      items: [
        {
          src: require("@/assets/img/watering.png"), //"https://picsum.photos/600/400/?image=0",
          thumbnail: require("@/assets/img/watering.png"),
          caption: "Watering Plants",
          id: "0",
        },
        {
          src: require("@/assets/img/welcome_image.png"),
          thumbnail: require("@/assets/img/welcome_image.png"),
          caption: "Watering Plants",
        },
        {
          src: require("@/assets/img/woman_and_child.png"),
          thumbnail: require("@/assets/img/woman_and_child.png"),
          caption: "Orphanage Project",
        },
      ],
    };
  },
};
</script>

<style scoped>
.gallery-section {
  padding: 5%;
}
</style>