<template>
  <div class="footer">
    <div class="details">
      <p>{{ location }}</p>
      <p>{{ address }}</p>
      <div class="voluteerBtn" @click="scrollTo('nav')">
        <router-link to="/activities"
          ><button>GET INVOLVED</button></router-link
        >
      </div>
    </div>
    <p class="copyright">{{ copyright }}</p>
  </div>
</template>

<script>
// import VolunteerButton from "@/components/VolunteerButton.vue";

export default {
  name: "Footer",
  components: {
    //  VolunteerButton
  },
  data() {
    return {
      location:
        "The Green Living Planet\nSombetini, Arusha\nTanzania, East Africa",
      address:
        "info@greenlivingplanet.org\nTel: +255 764 181 825\nP.O. Box 14888 Arusha",
      copyright:
        "All Rights Reserved. The Green Living Planet. " + this.getYear() + ".",
    };
  },

  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    getYear() {
      var date = new Date();
      var year = date.getFullYear();
      return year;
    },
  },
};
</script>

<style scoped>
.footer {
  padding: 5%;
  background: #333;
}
.footer .details {
  display: flex;
  /* padding: 5%; */
  align-items: center;
  justify-content: space-evenly;
}
.voluteerBtn {
  display: grid;
  place-items: center;
  /* background-color: lightcoral; */
  width: 30%;
}
.button {
  display: grid;
  place-items: left;
}
button {
  max-width: 200px;
  background-color: #006233;
  color: #fff;
  padding: 10px;
  font-weight: bold;
  font-size: 20px;
  border: none;
}

.footer .details p {
  white-space: pre-line;
  width: 30%;
  padding: 20px;
  text-align: left;
  color: #fff;
  /* font-family: "Gill Sans"; */
  font-size: 24px;
  line-height: 28px;
}

.footer .copyright {
  color: #e0e0e0;
  /* font-family: "Gill Sans"; */
  font-size: 24px;
  font-weight: 300;
  line-height: 27px;
  text-align: center;
  margin: unset;
  margin-top: 50px;
}
/* 
.footer .details .button {
  width: 30%;
  align-content: end;
  align-items: stretch;
} */
@media screen and (max-width: 720px) {
  .footer .details {
    display: grid;
    place-items: center;
    margin: auto;
  }
  .voluteerBtn {
    /* background-color: lightcoral; */
    width: 100%;
  }
  .footer .details p {
    text-align: center;
    width: unset;
    font-size: 18px;
  }
  .voluteerBtn {
    display: unset;
  }
  .footer .copyright {
    padding: 10px;
    margin-top: 50px;
    color: #e0e0e0;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 20px;
  }
  .footer {
    padding: unset;
  }
}
</style>