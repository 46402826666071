<template>
  <div id="nav">
    <div id="mySidepanel" class="sidepanel">
      <div class="mobile_menu">
        <div class="Close">
          <a href="javascript:void(0)" class="closebtn" @click="closeNav()">
            <!-- <img src="../../assets/icons/close.png" alt="" /> -->X
          </a>
        </div>
        <div class="mobileMenu">
           <li @click="scrollTo('nav'),closeNav()">
              <router-link to="/">HOME</router-link>
            </li>
            <li @click="closeNav()">
              <router-link to="/about">ABOUT</router-link>
            </li>
            <li @click="scrollTo('acommodation'),closeNav()">
              <router-link to="/">ACCOMODATION</router-link>
            </li>
            <li @click="scrollTo('contact'),closeNav()">
              <router-link to="/">CONTACT</router-link>
            </li>
            <li class="orderButton" @click="closeNav()">
              <router-link to="/activities">GET INVOLVED</router-link>
            </li>

          <!-- <hr /> -->
        </div>
      </div>
    </div>

    <nav class="navbar navbar-inverse">
      <div class="container">
        <div class="navbar-header">
          <button @click="openNav()" type="button" class="navbar-toggle">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <div class="navbar-brand" @click="closeNav()">
            <router-link to="/">
            <h1>THE GREEN <br>LIVING PLANET</h1>
              <!-- <img src="../../assets/icons/Green-House-Logo.png" /> -->
            </router-link>
          </div>
        </div>
        <div
          class="collapse navbar-collapse"
          id="myNavbar"
          style="float: right"
        >
          <ul class="nav navbar-nav">
            <li @click="scrollTo('nav')">
              <router-link to="/">HOME</router-link>
            </li>
            <li @click="scrollTo('nav')">
              <router-link to="/about">ABOUT</router-link>
            </li>
            <li @click="scrollTo('accomodation')">
              <router-link to="/about">ACCOMODATION</router-link>
            </li>
            <li @click="scrollTo('contact')">
              <router-link to="/">CONTACT</router-link>
            </li>
            <li class="orderButton">
              <router-link to="/activities">GET INVOLVED</router-link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    openNav() {
      document.getElementById("mySidepanel").style.height = "100%";
      document.getElementById("mySidepanel").style.width = "100%";
    },
    closeNav() {
      document.getElementById("mySidepanel").style.height = "0";
      document.getElementById("mySidepanel").style.width = "0";
    },
    menu(id) {
      var element = document.getElementById(id);
      if (element.style.height == "140px") {
        element.style.height = "0px";
      } else {
        element.style.height = "140px";
      }
    },
  },

  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style scoped>
#nav a {
  color: #006233;
  font-size: 20px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  font-weight: bolder;
}
#nav a:hover{
  text-decoration: none;
}
/* #nav a.router-link-exact-active {
  color: rgba(0, 98, 51, 0.7);
} */
#nav {
  width: 100%;
  /* position: fixed; */
  top: 0;
  z-index: 10;
}
.orderButton {
  background-color: #006233;
  border-radius: 3px;
}
#nav .orderButton a {
  color: #fff;
  padding-left: 20px;
}
.container {
  width: 90%;
  padding: 20px 0px 0% 0px;
}
.navbar-header button {
  border: none;
}
.navbar-header button:hover {
  background-color: unset;
}
.navbar-header button .icon-bar {
  background-color: #232323;
}
.apps {
  display: flex;
  padding: unset;
}
.navbar-inverse {
  /* background-image: linear-gradient(180deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 1) 80%); */
  background-color: transparent;
  width: 100%;
  margin: unset;
  border: none;
  border-radius: unset;
  vertical-align: middle;
  margin-bottom: 20px;
}
.content {
  display: flex;
  width: 80vw;
  height: 50px;
  margin-left: auto;
  margin-right: auto;
}
.space {
  content: "";
  width: 100%;
}

.navbar-nav li,
.apps li {
  text-align: center;
  height: 40px;
  margin: 5px;
  display: grid;
  place-items: center;
}
.navbar-inverse .navbar-nav > li > a {
  font-size: 18px;
  margin-right: 20px;
  color: #000;
  padding: unset;
}
.navbar-inverse .navbar-nav > li > a:hover {
  color: #000;
}
.navbar-brand {
  margin-top: -15px;
  padding: 0px;
  position: relative;
  /* z-index: 10; */
}
.navbar-brand img {
  z-index: 100;
  height: 76.13px;
}
#nav .navbar-brand a h1{
  text-align: left;
  font-weight: bold;
  margin: unset;
  font-size: 30px;
  text-decoration: none;
}
img:hover {
  cursor: pointer;
}
.sidepanel {
  width: 0%;
  position: fixed;
  z-index: 10;
  height: 0%;
  top: 0;
  right: 0;
  background-color: #ffffff;
  overflow-x: hidden;
  transition: 0.5s;
}

.sidepanel a {
  text-align: left;
  font-weight: 500;
  padding: 8px 8px 8px 24px;
  text-decoration: none;
  font-size: 16px;
  color: #141414;
  display: block;
  transition: 0.3s;
  cursor: pointer;
}
.mobile_menu li {
  list-style: none;
  margin: 5px 5px 5px 0px;
}
.Close a {
  font-size: 45px;
  padding-bottom: 30px;
  padding-left: 20px;
}

.sidepanel .closebtn {
  padding: 0px;
  position: absolute;
  z-index: 1000;
  top: 28px;
  right: 32px;
}
.mobileMenu {
  margin-top: 100px;
}
.mobileMenu hr {
  border: 1px solid #343540;
  /* width: 90%; */
  margin-left: 25px;
  margin-right: 25px;
}
@media(max-width:1100px)  {

    .navbar-collapse.collapse {
        display: none !important;
    }

    .navbar-collapse {
        overflow-x: visible !important;
    }

    .navbar-collapse.in {
      overflow-y: auto !important;
    }

    .collapse.in {
      display: block !important;
    }
.navbar-toggle{
  position: absolute;
  right: 0;
  display: inherit;
}
.navbar-brand br{
  display: none;
}
.navbar-brand h1{
  margin-top: 20px;
}
}
@media screen and (max-width:560px){
  .navbar-brand br{
  display: unset;
}
.navbar-brand h1{
  margin-top: unset;
}
}
/* @media screen and (max-width: 865px) {
  .navbar-inverse .navbar-nav > li > a {
    margin-right: unset;
  }
}
@media screen and (max-width: 780px) {
  .container {
    width: 93%;
  }
  .navbar-brand {
    z-index: 50;
  }
}
@media screen and (max-width: 700px) {
  .container {
    width: 90%;
  }
}
@media screen and (max-width: 470px) {
  .navbar-brand {
    margin-left: 10px;
  }
  .container {
    padding-top: 20px;
  }
} */
</style>