<template>
  <div id="app">
    <Header/>
    <router-view />
    <Footer/>
  </div>
</template>

<script>
import Footer from "@/components/layouts/Footer.vue";
import Header from "@/components/layouts/Header.vue";

export default {
  name:"App"
  , components:{
    Header,
     Footer
  }
}
</script>



<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #006233;
  font-family: "Poppins";
  max-width: 1600px;
  margin: auto;
}

*{
  margin: 0px;
  padding: 0px;
}
</style>
