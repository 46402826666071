<template>
  <div class="home">
    <Welcome
      :title="welcome.title"
      :subtitle="welcome.subtitle"
      :backgroundStyle="welcome.backgroundStyle"
    />
    <Programs
      v-for="HomeAbout in HomeAboutUs"
      :key="HomeAbout.id"
      :id="HomeAbout.id"
      :Title="HomeAbout.Title"
      :Description="HomeAbout.Description"
      :Programimage="HomeAbout.Programimage"
      :showToAccomodation="true"
    />
    <ActionStatementSection :text="action.text" :cta="action.cta" />
    <Programs
      v-for="activitie in activities"
      :key="activitie.id"
      :id="activitie.id"
      :Title="activitie.Title"
      :Description="activitie.Description"
      :Programimage="activitie.Programimage"
      :showToAccomodation="true"
    />
    <GallerySection />
    <div></div>
    <Programs
      v-for="acommodation in acommodations"
      :key="acommodation.id"
      :id="acommodation.id"
      :Title="acommodation.Title"
      :Description="acommodation.Description"
      :Programimage="acommodation.Programimage"
      :showToAccomodation="true"
    />
    <ContactForm />
  </div>
</template>

<script>
// @ is an alias to /src
import Welcome from "@/components/WelcomeSection.vue";
// import AboutSection from "@/components/AboutSection.vue";
import ActionStatementSection from "@/components/ActionStatementSection.vue";
// import ActivitesSection from "@/components/ActivitesSection.vue";
import GallerySection from "@/components/GallerySection.vue";
import Programs from "@/components/Programs.vue";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "Home",
  components: {
    Welcome,
    // AboutSection,
    ActionStatementSection,
    // ActivitesSection,
    GallerySection,
    Programs,
    ContactForm,
  },
  data() {
    return {
      welcome: {
        title: "THE GREEN LIVING PLANET",
        subtitle:
          "Promoting environmental conservation, sustainable development, and human welfare.",
        backgroundStyle: {
          backgroundImage: `url(${require("../assets/img/welcome_image.png")})`,
        },
      },
      action: {
        text: "We help educate people about the environment, supporting them to better understand how to preserve the nature around us.",
        cta: "BECOME A VOLUNTEER",
      },
      acommodations: [
        {
          Title: "Accommodation",
          Description:
            "We have a number of rooms to choose from at The Greenhouse, from small dorms to large dorms and private rooms with en-suite facilities.",
          Programimage: require("../assets/Activities/access-to-clean-water.jpg"),
        },
      ],
      HomeAboutUs: [
        {
          Title: "About Us",
          Description:
            "Promoting environmental conservation, sustainable development, and human welfare and advancement by bringing basic education and awareness about both environment to Tanzanians.",
          Programimage: require("../assets/Activities/access-to-clean-water.jpg"),
        },
      ],
      activities: [
        {
          id: 2,
          Title: "Get Involved",
          Description:
            "Volunteers have the opportunity to get involved in any of our active projects that are all focused aronding supporting local communities in Arusha with environmental activities.",
          Programimage: require("../assets/Activities/activities.jpg"),
        },
      ],
    };
  },
};
</script>
