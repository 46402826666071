<template>
  <div class="action-statement-section">
    <div class="gradient-box"></div>

    <div class="content-section">
      <p>{{ text }}</p>
      <div @click="scrollTo('contact')">
        <router-link to="/"
          ><button>{{ cta }}</button></router-link
        >
      </div>
    </div>
    <div class="gradient-box-bottom"></div>
  </div>
</template>

<script>
export default {
  name: "ActionStatementSection",
  props: {
    text: String,
    cta: String,
  },
  methods:{
     scrollTo(id) {
      const element = document.getElementById(id);
      element.scrollIntoView({ behavior: "smooth" });
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  }
};
</script>

<style scoped>
.action-statement-section {
  /* height: 200vh; */
  background-color: #006233;
}
.gradient-box {
  height: 400px;
  width: 100%;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 65%);
  position: relative;
  top: 0%;
  z-index: 0;
}
.gradient-box-bottom {
  height: 400px;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 65%);
  position: relative;
  bottom: 0%;
  z-index: 0;
}

p {
  color: #fff;
  /* font-family: "Gill Sans"; */
  font-weight: 600;
  font-size: 40px;
  line-height: 45px;
  text-align: center;
  padding-left: 10%;
  padding-right: 10%;
}
.action-statement-section .content-section {
  padding-top: 10%;
  padding-bottom: 10%;
  margin: 0%;
  /* padding: 20%; */
}
.content-section,
button {
  margin-top: 20px;
  color: #006233;
  /* font-family: "Gill Sans"; */
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  padding: 20px;
  min-width: 304px;
}
@media screen and (max-width: 720px) {
  p {
    color: #fff;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
  }
  .content-section,
  button {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 700;
    padding: 10px;
    min-width: 204px;
  }
}
</style>